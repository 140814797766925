:root {
    --scrollbarBackground: #eaeaea;
    --purple: #ac4385;
}

html,
body {
    height: 100%;
    overflow: hidden;
}

@font-face {
    font-family: 'OpenSansHebrew-Light';
    src: url('assets/fonts/OpenSansHebrew-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSansHebrew-Regular';
    src: url('assets/fonts/OpenSansHebrew-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSansHebrew-Bold';
    src: url('assets/fonts/OpenSansHebrew-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSansHebrew-ExtraBold';
    src: url('assets/fonts/OpenSansHebrew-ExtraBold.ttf') format('truetype');
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background-color: var(--scrollbarBackground);
}

::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 20px;
}

* {
    overscroll-behavior-y: contain;
  }

  @media print {
      @viewport {
          width: 1920px;
      }

      html,
      body {
          width: 1200px !important;
        overflow: visible !important;
        print-color-adjust: exact;
        zoom: 80% !important;
    }


    .print_hide{
        display: none;
    }

    .print_hide_margin {
        margin-inline: 10px !important;
    }

    .print_hide_background {
        background-color: white !important;
    }

    .container {
        width: 100% !important;
        height: 100% !important;
        padding: 0;
        margin: 0;
    }

    .MuiCollapse-root {
        height: auto !important;
        visibility: visible !important;
        overflow: visible !important;
    }

    .MuiPaper-root{
        page-break-inside: avoid !important;
    }

    #scrollable {
        overflow: visible !important;
        overflow-y: unset !important;
        display: block !important;
        height: auto !important;
    }

    .print-deposition {
        display: flex !important;
        page-break-inside: avoid !important;
        height: 290mm;
        page-break-after: auto !important;
    }

    .print-logo {
        display: flex !important;
    }

    .footer {
        padding-bottom: 20px;
    }

    .MuiAccordionSummary-expandIconWrapper {
        display: none !important;
    }

}

.pull-indicator {
    position: absolute;
    top: 49px;
    left: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
    z-index: 10;
  }

  .pull-indicator img {
    transition: transform 0.2s ease-in-out;
  }

  .pull-indicator.flip img {
    transform: rotate(180deg);
  }

  .pull-indicator__img  {
    width: 40px;
    height: 40px;
  }
