.tooltip {
	background: #ffffff;
	border-radius: 5px;
	height: 50px;
	width: 85px;
	padding: 5px 5px;
	pointer-events: none;
	position: absolute;
	transform: translate(-50%, 0);
	transition: all .1s ease;
	box-shadow: 2px 2.2px 10.8px 2.2px rgba(0, 1, 0, 0.31);
	display: flex;
	align-items: center;
	justify-content: center;
}

.tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 35px;
	border-style: solid;
	border-width: 10px 10px 0;
	border-color: #FFFF transparent;
	display: block;
	width: 0;
	z-index: 0;
}
